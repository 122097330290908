
import { Component, Prop, Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";

import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";

@Component({
  components: {
    Multiselect
  }
})
export default class mUserSelector extends Vue {
  @Prop({ type: String, default: () => "User" }) label: string;
  @Prop({ type: Boolean, default: () => false }) removeSelf: boolean;
  @Prop({ type: Boolean, default: () => false }) multiple: boolean;
  @Prop({ type: Boolean, default: () => false }) closeOnSelect: boolean;


  userModel: UserOption | null = null;
  users: UserOption[] = [];
  clientId = null;

  async beforeMount(){
    if(this.$router.currentRoute.query["clientId"]){
      this.clientId = this.$router.currentRoute.query["clientId"]
    }
  }

  async loadUsers() {
    if(!await AuthenticationService.Instance.isAdmin()){
      return;
    }

    var query = ""
    if(this.clientId){
      query = `?clientId=${this.clientId}`
    }

    const usersResponse = await AuthenticationService.Instance.http.get(
      `${ConfigService.Instance.backEndUrl}/users${query}`
    );

    let users = usersResponse.data
    
    const tempUsers = (users as any[]).map(
      user =>
        ({
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          type: true
        } as UserOption)
    );

    if (this.removeSelf) {
      const currentUser =
        AuthenticationService.Instance.currentAuthenticatedUser;
      users = users.filter(x => x.Id !== currentUser.Id);
    }

    tempUsers.sort(this.compare);
    this.users = tempUsers;
  }

  customLabel(option) {
    return `${option.firstName} ${option.lastName} (${option.email})`;
  }

  public getSelectedUserContactInfo(): string {
    if (this.userModel) {
      return this.userModel.type ? this.userModel.email : this.userModel.userId;
    }
    return "";
  }

  public getSelectedUserId(): string {
    return (this.userModel && this.userModel.userId) || "";
  }

  public getSelectedUsersIds(): string [] {
    if(this.userModel!=null){
      return Object.values(this.userModel).map(x => x.userId)
    }
    return null;
  }

  public getSelectedUserEmail(): string {
    return (this.userModel && this.userModel.email) || "";
  }

  public reset() {
    this.userModel = null;
  }

  async created() {
    await this.loadUsers();
  }

  compare(userA, userB) {
    if (userA.firstName.toUpperCase() > userB.firstName.toUpperCase()) {
      return 1;
    } else if (userA.firstName.toUpperCase() < userB.firstName.toUpperCase()) {
      return -1;
    }
    return 0;
  }
}

export interface UserOption {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  type: boolean;
}
