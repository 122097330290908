
import { Component, Vue } from "vue-property-decorator";
import mDataTable, {
  Field,
  OptionMenuItem
} from "@/components/mDataTable.vue";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import DataTableService from "@/services/DataTableService";
import ConfigService from "@/services/ConfigService";
import AuthenticationService, {
  Roles,
  RoleType
} from "@/services/AuthenticationService";

@Component
export default class ClientList extends Vue {
  fields: Field[] = [
    { title: "Name", prop: "name", width: 35, main: true },
    { title: "Mantle Client ID", prop: "id", width: 30 },
    {
      title: "Creation Date",
      prop: "creationDate",
      width: 25,
      render: DataTableService.renderDate()
    }
  ];
  menuItems: OptionMenuItem[] = [
    {
      title: "Invites",
      icon: "fa-envelope-open",
      onClick: this.onClientInvitesClick
    },
    { title: "Edit", icon: "fa-edit", onClick: this.onEditClientClick },
    { title: "Delete", icon: "fa-trash", onClick: this.onDeleteClientClick },
    { title: "See Receipts", icon: "fa-receipt", onClick: this.onSeeReceiptsClick },
    { title: "Service Accounts", icon: "fa-cog", onClick: this.onServiceAccountClick }
  ];
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  endpoint = "/clients";
  showCreateModal = false;
  showEditModal = false;
  showDeleteModal = false;
  clientId = "";
  clientIdToDelete = "";
  clientName = "";

  onClientInvitesClick(element: any) {
    this.$router.push({ path: `/admin/clients/${element.id}/invites` });
  }

  onClientProductsClick(element: any) {
    this.$router.push({ path: `/admin/clients/${element.id}/products` });
  }

  onCreateClientClick() {
    this.showCreateModal = true;
  }

  onEditClientClick(element: any) {
    this.clientName = element.name;
    this.clientId = element.id;
    this.showEditModal = true;
  }

  onDeleteClientClick(element: any) {
    this.clientIdToDelete = element.id;
    this.showDeleteModal = true;
  }
  onServiceAccountClick(element:any) {
    this.$router.push({ path: `/settings/serviceaccount?clientId=${element.id}` });
  }

  onSeeReceiptsClick(element:any) {
    this.$router.push({ path: `/gateway/receipt?clientId=${element.id}` });
  }

  onCloseDeleteModal() {
    this.showDeleteModal = false;
    this.clientIdToDelete = "";
  }

  async onDeleteClientConfirm() {
    await AuthenticationService.Instance.http.delete(
      `${ConfigService.Instance.backEndUrl}/clients/${this.clientIdToDelete}`
    );
    this.onCloseDeleteModal();
    (this.$refs.clientTable as mDataTable).refresh();
  }

  onCloseCreateModal() {
    this.showCreateModal = false;
    this.clientName = "";
  }

  onCloseEditModal() {
    this.showEditModal = false;
    this.clientName = "";
    this.clientId = "";
  }

  async onEditClientConfirm() {
    const result = await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/clients`,
      { Name: this.clientName, ClientId: this.clientId }
    );
    this.onCloseEditModal();
    (this.$refs.clientTable as mDataTable).refresh();
  }

  async onCreateClientConfirm() {
    const result = await AuthenticationService.Instance.http.post(
      `${ConfigService.Instance.backEndUrl}/clients`,
      {
        Name: this.clientName
      }
    );
    this.onCloseCreateModal();
    (this.$refs.clientTable as mDataTable).refresh();
  }
}
